import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowtToTitle from "../components/howtotitle"
import HTMarktetResearch from "../components/ht-mr"
import HTCustomerSatisfaction from "../components/ht-cs"
import HTEmployeeEvaluation from "../components/ht-ee"
import HTEmployeeSatisfaction from "../components/ht-es"
import { graphql } from "gatsby"
import bg from "../images/staticsvgback.svg"
import { useTranslation } from "react-i18next"

const SurveySetup = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t("Setup Survey")} />
      <div
        className="py-5 bg-light"
        style={{
          background: `url(${bg}) no-repeat fixed center`,
          backgroundSize: "contain",
        }}
      >
        <HowtToTitle />
        {/* <Timeline /> */}
        <div className="gap-5 d-flex flex-column py-5">
          <HTMarktetResearch />
          <HTCustomerSatisfaction />
          <HTEmployeeEvaluation />
          <HTEmployeeSatisfaction />
        </div>
      </div>
    </Layout>
  )
}

export default SurveySetup

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
