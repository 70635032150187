import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "react-i18next"
import { Link } from "gatsby"

export default function HTEmployeeSatisfaction() {
  return (
    <div
      className="container border shadow p-5 bg-white"
      style={{ borderRadius: "1rem" }}
    >
      <h4 className="bg-dark p-4 text-light text-center rounded shadow">
        <Trans>Employee Satisfaction</Trans>
      </h4>
      <div className="row pt-5 ">
        <h4 className="text-center">
          <Trans>Guidelines for setting up</Trans>
        </h4>
        <hr className="dropdown-divider"></hr>
        <div
          className="col-sm-12 col-lg-6 d-flex justify-content-center
    flex-wrap align-content-center"
        >
          <StaticImage
            src="../images/istockphoto-1254879882-612x612.jpg"
            quality={95}
            // width={"auto"}
            formats={["auto", "webp", "avif"]}
            alt="Employee Satisfaction"
            style={{ objectFit: "contain" }}
          />
          <Link to="https://app.uhappy.gr">
            <button className="btn btn-dark btn-lg shadow m-5">
              <Trans>Create Survey</Trans>
            </button>
          </Link>
        </div>

        <div className="col-sm-12 col-lg-6">
          <ul className="pt-5">
            <li>
              <Trans>Create your questions</Trans>
              <br />{" "}
              <small className="text-muted">
                *{" "}
                <Trans>
                  Enable evaluation and add score logic on each question
                </Trans>
              </small>
            </li>
            <li>
              <Trans>Add Theme</Trans>
              <br />
              <small className="text-muted">
                *{" "}
                <Trans>
                  Choose colors, fonts, background and add logo to your form
                </Trans>
              </small>
              <br />
            </li>
            <li>
              <Trans>Submit</Trans>
              <br />
              <small className="text-muted">
                *{" "}
                <Trans>
                  Enable identification and enter identification type 'name' for
                  the employee to fill in their name
                </Trans>
              </small>{" "}
            </li>
            <li>
              <Trans>Share the generated link or QR image code</Trans>{" "}
            </li>
            <li>
              <Trans>Track results</Trans>
            </li>
          </ul>
          <small className="text-muted">
            {" "}
            * <Trans>Optional</Trans>{" "}
          </small>
        </div>
      </div>
    </div>
  )
}
