import React from "react"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { Trans } from "gatsby-plugin-react-i18next"
import Timeline from "./timeline"

export default function HowtToTitle() {
  return (
    <div
      className="container text-center 
         justify-content-center align-content-center
         align-items-center w-100"
    >
      <h1 className="p-5 display-2">
        <Trans>Setup Survey</Trans>
      </h1>
      <Timeline />

      <div className="py-5 px-3 d-flex justify-content-center align-items-center">
        <FaQuoteLeft className="text-warning display-6" />
        <div className="lead font-italic mx-3 display-6">
          <Trans>create surveys and evaluation forms based on your needs</Trans>
        </div>
        <FaQuoteRight className="text-warning display-6" />
      </div>
    </div>
  )
}
