import React from "react"
import { AiOutlineForm } from "react-icons/ai"
import { BiSend } from "react-icons/bi"
import { FaChartPie, FaBrush } from "react-icons/fa"
import { Trans } from "react-i18next"

export default function Timeline() {
  return (
    <section className="ps-timeline-sec">
      <div className="container">
        <ol className="ps-timeline">
          <li>
            <div className="img-handler-top">
              <AiOutlineForm className="display-1 text-dark" />
            </div>
            <div className="ps-bot text-center w-100">
              <h4>
                <Trans>Create Questions</Trans>
              </h4>
            </div>
            <span className="ps-sp-top">1</span>
          </li>
          <li>
            <div className="img-handler-bot">
              <FaBrush className="display-1 text-dark" />
            </div>
            <div className="ps-top text-center w-100">
              <h4>
                <Trans>Add Style</Trans>
              </h4>
            </div>
            <span className="ps-sp-bot">2</span>
          </li>
          <li>
            <div className="img-handler-top">
              <BiSend
                className="display-1 text-warning"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="ps-bot text-center w-100">
              <h4>
                <Trans>Submit</Trans>
              </h4>
            </div>
            <span className="ps-sp-top">3</span>
          </li>
          <li>
            <div className="img-handler-bot">
              <FaChartPie className="display-1 text-dark" />
            </div>
            <div className="ps-top text-center w-100">
              <h4>
                <Trans>Evaluate Results</Trans>
              </h4>
            </div>
            <span className="ps-sp-bot">4</span>
          </li>
        </ol>
      </div>
    </section>
  )
}
